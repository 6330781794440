<template>
    <form class="base-form">
        <slot />
    </form>
</template>

<style lang="less">
.base-form {
    margin-top: 2rem;

    fieldset {
        display: block;
        border: none;
        padding: 0;
        margin: 0;
    }

    legend {
        .typography-h5();

        + p {
            .typography-small();
            margin-top: .25rem;
        }
    }
}
</style>